import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import store from "..";
import {
    addCandidatesToHiringManager,
    deleteCandidatesData,
    getCandidateDetails,
    getCandidatesData,
    getListName,
    leavePage,
    setCandidateDetails,
    setCandidatesData,
    setHMDetails,
    setListExpired,
    setListName,
    setPassCode,
    setProjectCreatedOn,
    setProjectDescription,
    setProjectFilters,
    setProjectLocation,
    setProjectName,
    setRecruiterName,
    setSelectedList,
    setShowPassCodeModal,
    shareWithHiringManager,
    updateCandidatesData,
} from "../reducers/hiring-manager/hiringManager.reducer";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import { IProjectStage } from "../reducers/project/project.types";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import API from "@/utils/API";
import { CancelSagas } from "@/utils/saga.utils";
import handleException from "@/utils/sentry";

function* addCandidatesToHiringManagerSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const state = yield select();

        const projectFiltersStateValue = state.project.projectFilters;
        const searchQuery = get(state, "project.searchQuery");
        const showByPersonalEmailsStateValue = get(state, "project.showByPersonalEmails");

        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);

        const finalPayload = { search: searchQuery, filterBy: appliedFilters, ...action.payload };

        if (action.payload.candidateIds.length === 0) {
            yield put(setErrorNotification("Please select candidates to be added to hiring manager's list"));
            return;
        }
        const response = yield call(new API().post, `hiringManager/add-to-hiring-manager`, finalPayload);

        if (response?.message) {
            yield put(setSuccessNotification("Candidates successfully added to hiring manager's list "));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getCandidateListSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { payload } = action;

        const state = store.getState();
        const search = new URLSearchParams(window.location.search);
        const passCode = get(state, "hiringManager.passCode");
        const viewMode = search.get("isViewOnly") === "true";

        const url = viewMode ? "/hiringManager/hiring-list" : "/hiringManager/hiring-list-view";

        if (viewMode) {
            yield put(setShowPassCodeModal(false));
        }else{
            if(passCode === "-1") {
                yield put(setShowPassCodeModal(true));
            }
        }

        let newPayload = payload;

        if (passCode !== "-1" && !viewMode) {
            newPayload = {
                passCode: passCode,
                ...newPayload,
            };
        }

        if(url === "/hiringManager/hiring-list-view" ) {
            if(!newPayload.passCode){
                return;
            }
        }
        
        const response = yield call(new API().post, url, newPayload);

        if (!response?.data) return;
        if (response?.data?.isListExpired) {
            yield put(setListExpired(true));
        }
        if(response?.data?.candidates) {
            yield put(setShowPassCodeModal(false));
        } 

        const candidates = response.data.candidates;
        const projectName = response.data.projectName;
        const projectDescription = response.data.description;
        const location = response.data.location?.[0]?.label;
        const projectCreatedOn = response.data.createdOn;
        const recruiterName = response.data.recruiterName;
        const projectFilter = {
            location: response?.data?.location,
            jobTitle: response?.data?.jobTitle,
            skills: response?.data?.skills,
            education: response?.data?.education,
            experience: response?.data?.experience,
            industry: response?.data?.industry,
        };
        const hiringManagerDetails = {
            name: response?.data?.hiringManagerName,
            email: response?.data?.hiringManagerEmail,
        };
        yield put(setHMDetails(hiringManagerDetails));
        yield put(setProjectFilters(projectFilter));
        yield put(setProjectName(projectName));
        yield put(setProjectName(projectName));
        yield put(setProjectDescription(projectDescription));
        yield put(setProjectLocation(location));
        yield put(setProjectCreatedOn(projectCreatedOn));
        yield put(setRecruiterName(recruiterName));
        yield put(setCandidatesData(candidates));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getCandidateDetailsSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { payload } = action;
        const candidateIds = payload.candidateId;
        const uuid = payload.uuid;
        if (candidateIds[0] === null || candidateIds[0] === undefined || candidateIds.length === 0) {
            return;
        }
        const response = yield call(new API().post, `/hiringManager/candidate-details`, {
            candidateIds,
            uuid,
        });
        if (!response?.data) return;

        const candidateDetails = response.data;
        yield put(setCandidateDetails(candidateDetails));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getListNameSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const { payload } = action;
        const projectId = payload.projectId;

        const response = yield call(new API().get, `/hiringManager/list-project-id-wise/${projectId}`);
        if (!response?.data) return;
        const listName = response.data;
        yield put(setSelectedList(listName[listName.length - 1]));
        yield put(setListName(listName));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* updateCandidateDataSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type + `${action?.payload?.userId}` }));
        const response = yield call(new API().post, `hiringManager/create-feedback`, action.payload);

        if (response?.message) {
            // yield put(getCandidatesData({ uuid: action.payload.uuid }));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type + `${action?.payload?.userId}` }));
    }
}

function* deleteCandidateDataSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `hiringManager/delete-hiring-review`, action.payload);

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
            yield put(getCandidatesData({ uuid: action.payload.uuid }));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* shareWithHiringManagerSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `hiringManager/send-to-hiring-manager`, action.payload);

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error: any) {
        handleException(error);
        yield put(setErrorNotification(error?.response?.data?.message || "Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getCandidatesData.type, getCandidateListSaga),
        // @ts-ignore
        yield takeLatest(getCandidateDetails.type, getCandidateDetailsSaga),
        // @ts-ignore
        yield takeLatest(deleteCandidatesData.type, deleteCandidateDataSaga),
        // @ts-ignore
        yield takeLatest(updateCandidatesData.type, updateCandidateDataSaga),
        // @ts-ignore
        yield takeLatest(addCandidatesToHiringManager.type, addCandidatesToHiringManagerSaga),
        // @ts-ignore
        yield takeLatest(shareWithHiringManager.type, shareWithHiringManagerSaga),
        // @ts-ignore
        yield takeLatest(getListName.type, getListNameSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
