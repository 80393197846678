import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ComputerIcon from "@mui/icons-material/Computer";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import InfoIcon from "@mui/icons-material/Info";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Menu, MenuItem } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

import { AttachmentList, AttachmentsModal } from "./components/Attachments";
import { CalendlyMenu } from "./components/CalendlyMenu";
import { CandidatesMenu } from "./components/CandidatesMenu";
import { ConfirmDelete } from "./components/ConfirmDelete";
import { CustomStack } from "./components/CustomStack";
import { CustomTitle } from "./components/CustomTitle";
import ExclusionList from "./components/ExclusingList";
import { MimicWritingStyles } from "./components/MimicWritingStyles";
import { OutreachElementIcon } from "./components/OutreachElementIcon";
import { PersonalizedMenu } from "./components/PersonalizeMenu";
import { SaveModal } from "./components/SaveModal";
import { FetchFromTemplate } from "./components/TemplatesMenu";
import { TestEmailModal } from "./components/TestEmailModal";
import { WarningModal } from "./components/WarningModal";
import useAddWorkflowStep from "./hooks/useAddWorkflowStep.hook";
import { useOutreachParams } from "./hooks/useOutreachParams.hook";
import { useSaveMultipleOutreachTemplates } from "./hooks/useSaveOutreach.hook";

import errorImage from "../../assets/img/outreach-error.svg";
import CustomButton from "../../components/CustomButton";
import { JoyProvider } from "../../components/JoyProvider";
import MUILoader from "../../components/MUILoader";
import Navbar from "../../components/Navbar/Navbar";
import style from "../../container/AppContainer/AppContainer.module.css";
import useDisclosure from "../../hooks/useDisclosure";
import useMenu from "../../hooks/useMenu";
import { RootState, useAppDispatch } from "../../store";
import {
    useDeleteWorkflowStepMutation,
    useFetchOutreachByIdQuery,
    useFetchOutreachCandidatesQuery,
    useFetchTimeRangeQuery,
    useFetchWritingStylesQuery,
} from "../../store/apis/outreach/outreach.api";
import {
    OutReachElementWithEventEnumAndName,
    OutReachEvent,
    OutreachElementProps,
    WorkflowStepValue,
} from "../../store/apis/outreach/outreach.types";
import {
    getElementDescription,
    getFutureTimestampWithSameTime,
    outreachTooltipMapping,
} from "../../utils/outreach.helpers";
import { TemplateEditorProvider } from "../OutReach/TemplateEditorProvider";

import { useRichTextEditorContext } from "@/components/RichTextEditor/RichTextEditorContext";
import { getProject, selectProjectName } from "@/store/reducers/allProjects/allProjects.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    addOutReachElement,
    checkEmailPresentInOutreachFlow,
    checkErrorInOutreachElement,
    checkErrorsInOutreach,
    checkIfOutreachReady,
    checkLinkedInPresent,
    checkSMSPresent,
    markCurrentTemplateAsSave,
    removeOutReachElement,
    selectCurrentOutreachElement,
    selectCurrentOutreachElementType,
    selectOutReachFlow,
    setIsScheduleRecruiterOnSameDay,
    setOutReachElementDays,
    setOutReachElementTime,
    shouldDisableDelete,
    toggleMobileView,
    validateOrdering,
} from "@/store/reducers/outreach/outreach.slice";
import {
    checkIsLinkedinAuthorized,
    checkRecruiterInMail,
    checkSMSEnabled,
    selectUser,
    selectUserOrgId,
} from "@/store/reducers/signin/Signin.reducer";
import { selectIsSuperAdminView } from "@/store/reducers/super-admin/superAdmin.reducer";
import { Badge, Button, Checkbox, Chip, Skeleton, Tooltip, Typography } from "@/ui";
import { Popover } from "@/ui/Popover/Popover";
import { Stack } from "@/ui/Stack/Stack";

type MenuAction = OutReachElementWithEventEnumAndName & {
    handleClick: () => void;
    label: string;
    icon: React.ReactNode;
};

function AddOutReachElementMenu() {
    const user = useSelector(selectUser);
    const { navigateToNextOutreachElement } = useOutreachParams();
    const dispatch = useAppDispatch();
    const {
        addWorkflowStep,
        saveMutationState: { isLoading: _isSavingTemplate },
    } = useAddWorkflowStep({
        successCallback: (data) => {
            const { type, eventBody } = data;
            const { subject, body, inMailBody, inMailSubject, connectionReminderMessageBody } = eventBody;
            dispatch(
                addOutReachElement({
                    eventName: type,
                    subject,
                    body,
                    inMailBody,
                    inMailSubject,
                    connectionReminderMessageBody,
                    onSuccess: (newOutreachElementId) => {
                        navigateToNextOutreachElement(newOutreachElementId);
                        setTimeout(() => {
                            const selectedOutreachElement = document.getElementById(
                                `outreach-element-${newOutreachElementId}`
                            );
                            if (selectedOutreachElement) {
                                selectedOutreachElement.scrollIntoView({ behavior: "smooth" });
                            }
                        }, 0);
                    },
                })
            );
        },
    });
    const { isHyperPersonalizedView } = useOutreachParams();
    const isSMSEnabled = useSelector(checkSMSEnabled);
    const isEmailPresent = useSelector((state: RootState) =>
        checkEmailPresentInOutreachFlow(state, isHyperPersonalizedView)
    );
    const isLinkedinPresent = useSelector(checkLinkedInPresent);
    const isSMSPresent = useSelector(checkSMSPresent);
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();

    const handleClickWithClose = (callback: () => void) => () => {
        callback();
        handleClose();
    };

    const actions: MenuAction[] = [
        {
            eventEnum: 1,
            eventName: "email",
            handleClick: handleClickWithClose(() => {
                addWorkflowStep("email");
            }),
            label: "Email",
            icon: <OutreachElementIcon eventType="email" props={{ fontSize: "small" }} />,
        },

        {
            eventName: "follow-up",
            eventEnum: 11,
            handleClick: handleClickWithClose(() => {
                addWorkflowStep("follow-up");
            }),
            label: "Follow-up email",
            icon: <OutreachElementIcon eventType="follow-up" props={{ fontSize: "small" }} />,
        },
        {
            eventName: "linkedin",
            eventEnum: 25,
            handleClick: handleClickWithClose(() => {
                addWorkflowStep("linkedin");
            }),
            label: "Linkedin",
            icon: <OutreachElementIcon eventType="linkedin" props={{ fontSize: "small" }} />,
        },
        {
            eventName: "SMS",
            eventEnum: 12,
            handleClick: handleClickWithClose(() => {
                addWorkflowStep("SMS");
            }),
            label: "SMS",
            icon: <OutreachElementIcon eventType="SMS" props={{ fontSize: "small" }} />,
        },
    ];

    return (
        <>
            <Tooltip title="Add outreach element">
                <div>
                    <CustomButton
                        onClick={handleClick}
                        className="text-sm px-4 py-1 rounded-lg bg-[#0891B2] border-0 shadow-none hover:bg-[#0891B2]"
                    >
                        Add
                    </CustomButton>
                </div>
            </Tooltip>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiPaper-root": {
                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {actions.map(({ label, handleClick, icon, eventName }) => {
                    if (eventName === "SMS" && isSMSPresent) {
                        return null;
                    }
                    if (eventName === "linkedin" && isLinkedinPresent) {
                        return null;
                    }

                    if (eventName === "email" && isEmailPresent) {
                        return null;
                    }

                    if (eventName === "follow-up" && !isEmailPresent) {
                        return null;
                    }
                    let tooltipTitle = "";
                    let disabled = false;
                    if (eventName === "SMS" && !isSMSEnabled) {
                        tooltipTitle = "Please contact your account manager to enable SMS";
                        disabled = true;
                    }

                    if (eventName === "SMS" && user.isRB2B) {
                        disabled = true;
                        tooltipTitle =
                            "Reach out to customer-support@hirequotient.com to upgrade and send SMS messages";
                    }
                    if (eventName === "linkedin" && user.isRB2B) {
                        disabled = true;
                        tooltipTitle = "Reach out to customer-support@hirequotient.com to upgrade and send LI messages";
                    }

                    return (
                        <Tooltip key={label} title={tooltipTitle} placement="right" arrow>
                            <span>
                                <MenuItem disabled={disabled} key={label} onClick={() => handleClick()}>
                                    <li>{icon}</li>
                                    <li
                                        style={{
                                            fontSize: "0.9rem",
                                            marginLeft: "0.5rem",
                                        }}
                                    >
                                        {label}
                                    </li>
                                </MenuItem>
                            </span>
                        </Tooltip>
                    );
                })}
            </Menu>
        </>
    );
}

function DeleteOutreachElement({ id }: { id: string }) {
    const {
        isOpen: isOpenConfirmDeleteModal,
        onOpen: onOpenConfirmDeleteModal,
        onClose: onCloseConfirmDeleteModal,
    } = useDisclosure();
    const { currentOutreachElementId, navigateToNextOutreachElement, isHyperPersonalizedView, outreachId } =
        useOutreachParams();
    const currentOutreachElement = useSelector((state: RootState) =>
        selectCurrentOutreachElement(state, { templateId: id })
    );
    const dispatch = useAppDispatch();
    const [deleteWorkflowSteps] = useDeleteWorkflowStepMutation();
    const disableDelete = useSelector((state: RootState) => shouldDisableDelete(state, id));

    if (isHyperPersonalizedView) {
        return null;
    }

    const handleDelete = () => {
        dispatch(markCurrentTemplateAsSave(currentOutreachElementId));
        setTimeout(() => {
            dispatch(
                removeOutReachElement({
                    currentSelectedTemplateId: currentOutreachElementId,
                    deleteTemplateId: id,
                    moveToOutreachElement: navigateToNextOutreachElement,
                    onDelete: (deletedIds: string[]) => {
                        setTimeout(() => {
                            deleteWorkflowSteps({
                                stepIds: [id, ...deletedIds],
                                workflowId: outreachId,
                            });
                        }, 0);
                        setTimeout(() => {
                            dispatch(validateOrdering());
                        }, 0);
                    },
                })
            );
        }, 0);
    };
    const isEmail = currentOutreachElement.eventName === "email";

    return (
        <>
            <Tooltip
                placement="right"
                title={
                    isEmail
                        ? "If you delete this email element, all follow-ups (if any) will also get deleted"
                        : "Delete"
                }
            >
                <button onClick={onOpenConfirmDeleteModal} disabled={disableDelete}>
                    <DeleteOutlinedIcon sx={{ fontSize: 18, color: "#d32f2e", marginBottom: "5px" }} />
                </button>
            </Tooltip>
            {isOpenConfirmDeleteModal && (
                <ConfirmDelete
                    deleteOutreachElementId={id}
                    onDelete={handleDelete}
                    onClose={onCloseConfirmDeleteModal}
                />
            )}
        </>
    );
}

function getElementColor(eventName: WorkflowStepValue["eventName"]) {
    switch (eventName) {
        case "email": {
            return "#fd185d";
        }
        case "SMS": {
            return "#fc5910";
        }
        case "linkedin": {
            return "#196FA8";
        }
        default:
            return "#FFBF00";
    }
}

// interval should update time of the first element at every 1 minute
// if index === 1 then schedule a interval that makes a dispatch call to update time;
// if index !== 1 and there is previous interval scheduled then clear it;
// on day change or time change same useEffect is going to fire;
// it should be fire during the entire life time of the page
// every time scheduletime got updated, useEffect is going to fire
// for time this useEffect runs and index matches then check whether there is some previous interval
// that is scheduled if it is then clear it and schedule a new one
// also on the unmount clear interval
function OutReachElement({
    eventName,
    _id,
    scheduledTime,
    scheduledDay,
    isSaved,
    index,
    nthFollowup,
}: OutreachElementProps) {
    const dispatch = useAppDispatch();
    const { currentOutreachElementId, navigateToNextOutreachElement, isHyperPersonalizedView } = useOutreachParams();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [timeValue, setTimeValue] = useState<Dayjs | null>(() => {
        return dayjs(scheduledTime);
    });
    const [day, setDay] = useState(() => String(scheduledDay));
    const isErrorInOutreachElement = useSelector((state: RootState) => checkErrorInOutreachElement(state, _id));
    const intervalRef = useRef<null | NodeJS.Timeout>(null);



    useEffect(() => {
        intervalRef.current = setInterval(() => {
            const isScheduledTimeBeforeCurrentTime = dayjs(scheduledTime).isBefore(dayjs());
            if (scheduledDay === 1 && isScheduledTimeBeforeCurrentTime) {
                dispatch(
                    setOutReachElementTime({
                        newTimeStamp: dayjs().toISOString(),
                        templateId: _id,
                        shouldReorder: false,
                        shouldMarkUnSave: false,
                    })
                );
            }
        }, 1000);
        return () => {
            if (intervalRef?.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [scheduledDay, scheduledTime, index, _id]);

    useEffect(() => {
        setTimeValue(dayjs(scheduledTime));
    }, [scheduledTime]);

    useEffect(() => {
        setDay(String(scheduledDay));
    }, [scheduledDay]);

    const handleElementClick = (_: React.MouseEvent<HTMLButtonElement>) => {
        if (isErrorInOutreachElement && isHyperPersonalizedView) {
            dispatch(setErrorNotification("Cannot generate preview as there may be errors in the template"));
            return;
        }
        navigateToNextOutreachElement(_id);
    };

    const handleClockClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const debounceDispatchTime = useCallback(
        debounce((value: string) => {
            dispatch(
                setOutReachElementTime({
                    newTimeStamp: value,
                    templateId: _id,
                })
            );
        }, 400),
        [_id]
    );

    const debounceDispatchDay = useCallback(
        debounce((value: number) => {
            dispatch(setOutReachElementDays({ templateId: _id, days: value }));
        }, 400),
        [_id]
    );

    const handleDaysChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const valueAsNumber = Number(e.target.value);

        // don't allow zeros and empty values
        if (value === "0" || valueAsNumber < 0) {
            setDay((prev) => prev);
        } else {
            setDay(value);
        }

        if (!isNaN(valueAsNumber) && valueAsNumber > 0) {
            debounceDispatchDay(valueAsNumber);
        }
    };

    const handleTimeChange = (newValue: dayjs.Dayjs | null) => {
        if (day === "1" && dayjs(newValue).isBefore(dayjs())) {
            dispatch(setErrorNotification("Invalid time (please do not select a time in the past)"));
            return;
        }
        if (newValue) {
            setTimeValue(newValue);
            debounceDispatchTime(newValue.toISOString());
        }
    };

    const handleClose = () => {
        // if user leaves days input as empty then initial state got set
        if (day === "") {
            setDay(String(scheduledDay));
        }
        setAnchorEl(null);
    };



    const isMatching = currentOutreachElementId === _id;
    const description = getElementDescription({
        elementType: eventName,
        day: getFutureTimestampWithSameTime(scheduledDay - 1, timeValue?.toISOString() || "").toISOString(),
        time: timeValue?.toISOString() || "",
    });

    return (
        <>
            <Stack alignItems="center" sx={{ position: "relative" }}>
                <Tooltip title={outreachTooltipMapping[eventName]}>
                    <button
                        data-component-name="Button"
                        onClick={handleElementClick}
                        style={{
                            padding: "0.6rem",
                            borderRadius: "40px",
                            backgroundColor: isErrorInOutreachElement
                                ? "#d32f2e"
                                : isMatching
                                  ? getElementColor(eventName)
                                  : "transparent",
                        }}
                        className={`hover:bg-[${isErrorInOutreachElement ? "#d32f2e" : getElementColor(eventName)}] hover: text-[white]`}
                        id={`outreach-element-${_id}`}
                    >
                        <OutreachElementIcon
                            eventType={eventName}
                            props={{
                                sx: () => ({
                                    color: isErrorInOutreachElement
                                        ? "white"
                                        : isMatching
                                          ? "white"
                                          : getElementColor(eventName),
                                    fontSize: "2rem",
                                }),
                            }}
                        />
                    </button>
                </Tooltip>
                <Tooltip title={isSaved ? "Saved" : "Not saved yet"}>
                    {isSaved ? (
                        <DoneAllRoundedIcon
                            fontSize="small"
                            color="success"
                            sx={{ position: "absolute", top: "0.7rem", right: "0.5rem" }}
                        />
                    ) : (
                        <PriorityHighRoundedIcon
                            fontSize="small"
                            color="primary"
                            sx={{ position: "absolute", top: "0.7rem", right: "0.5rem" }}
                        />
                    )}
                </Tooltip>
                <Typography
                    variant="body2"
                    className={`text-center text-sm font-semibold ${
                        isMatching ? "pt-1" : "pt-0"
                    } text-gray-500 w-[150px]`}
                >
                    {outreachTooltipMapping[eventName]} {nthFollowup}
                </Typography>
                <Stack direction="row" alignItems="center" pl={2}>
                    <Tooltip title="Edit date and time">
                        <button onClick={handleClockClick}>
                            <Typography
                                variant="body2"
                                className={`text-center text-[13px] w-[110px] italic ${
                                    isErrorInOutreachElement ? "text-red-500" : "text-gray-600"
                                } hover:cursor-pointer hover:font-semibold`}
                            >
                                Day {day}, {timeValue?.format("h:mm A")}
                            </Typography>
                        </button>
                    </Tooltip>
                    <Tooltip title="Remove element">
                        <DeleteOutreachElement id={_id} />
                    </Tooltip>
                </Stack>
            </Stack>
            <Popover
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                className="max-w-[630px]"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Stack p={2} alignItems="center">
                    <Stack gap={1}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" gap={1} alignItems="center">
                                <Typography variant="body2">Execute this outreach step on day</Typography>
                                <input
                                    value={day}
                                    type="number"
                                    onChange={handleDaysChange}
                                    style={{
                                        width: "75px",
                                        padding: 7,
                                        fontSize: "14px",
                                        backgroundColor: "white",
                                        borderRadius: "4px",
                                        border: "0.5px solid lightgrey",
                                    }}
                                />
                                <Typography variant="body2">at time</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MultiSectionDigitalClock
                                        value={timeValue}
                                        onChange={handleTimeChange}
                                        timeSteps={{ hours: 1, minutes: 1 }}
                                        sx={{
                                            height: "50px",
                                            width: "fit-content",
                                            "& .MuiButtonBase-root": {
                                                padding: "0",
                                                fontSize: "14px",
                                                scrollBehavior: "smooth",
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Stack>
                        <Typography variant="body2" className="italic">
                            <div dangerouslySetInnerHTML={{ __html: description }} />
                        </Typography>
                    </Stack>
                </Stack>
            </Popover>
        </>
    );
}

function OutReachFlow() {
    const { currentOutreachElementId, outreachId , navigateToNextOutreachElement } = useOutreachParams();
    const stackRef = useRef<HTMLDivElement>(null);
    const { isHyperPersonalizedView } = useOutreachParams();
    const outreach = useSelector(selectOutReachFlow);
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const user = useSelector(selectUser);
    const userId = user?._id;
    const isRecruiterInMail = useSelector(checkRecruiterInMail);
    const orgId = useSelector(selectUserOrgId);
    const dispatch = useDispatch();
    const { data = [] } = useFetchOutreachCandidatesQuery({
        outreachId,
    });
    const candidateCount = data?.length;

    const { data: timeData } = useFetchTimeRangeQuery({
        candidateCount,
        isSuperAdminView,
        userId,
    });

    const totalCandidates = data?.length;
    const totalMinutes = totalCandidates * 3;
    const roundedTime = Math.ceil(totalMinutes / 10) * 10;
    const displayTime =
        roundedTime >= 60
            ? `${Math.floor(roundedTime / 60)} hr${roundedTime % 60 > 0 ? ` ${roundedTime % 60} min` : ""}`
            : `${roundedTime} min`;

    const apiTime = timeData?.totalTime ?? displayTime;
    const hasLinkedinOnDay1 = outreach?.some(
        (item: any) =>
            item?.eventName?.toLowerCase() === "linkedin" &&
            item?.scheduledDay === 1 &&
            item?.eventBody?.inMailStatus === true
    );
    useEffect(() => {
        if (outreach.length > 0 && !outreach.some(element => element._id === currentOutreachElementId)) {
            navigateToNextOutreachElement(outreach[0]._id);
        }
    }, [outreach, currentOutreachElementId]);
    const isSameDayInMailChecked = outreach?.some((item: any) => {
        return item?.eventName?.toLowerCase() === "linkedin" && item?.eventBody?.isScheduleRecruiterOnSameDay === true;
    });
    const linkedinElement = outreach?.find((item) => item.eventName === "linkedin");
    const linkedinElementId = linkedinElement?._id ?? linkedinElement?.stepId;

    const handleSameDayRecruiterInmails = () => {
        dispatch(setIsScheduleRecruiterOnSameDay({ currentOutreachElementId: linkedinElementId }));
    };

    let followupCount = 0;
    return (
        <CustomStack id="outreach-flow">
            <Stack
                style={{ backgroundColor: "#f5f5f5" }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
                px={1.5}
                py={1.15}
            >
                <CustomTitle>Outreach Flow</CustomTitle>
                {!isHyperPersonalizedView && (
                    <Stack direction="row" alignItems="center" gap={1}>
                        <AddOutReachElementMenu />
                        {/* <OutReachMode /> */}
                    </Stack>
                )}
            </Stack>
            <hr className="border-t border-gray-300" />
            <Stack
                ref={stackRef}
                direction="column"
                paddingBottom={25}
                style={{
                    maxWidth: "100%",
                    overflow: "auto",
                    background: "%fafafa",
                }}
            >
                <div
                    style={{
                        padding: "16px 0px",
                        height: "calc(100vh - 100px)",
                    }}
                >
                    {outreach?.map((element, index) => {
                        const isLast = index === outreach.length - 1 ? true : false;
                        const isFollowup = element?.eventName === "follow-up";
                        const nthFollowup = isFollowup ? ++followupCount : "";
                        const isMatching = currentOutreachElementId === element._id ;
                        return (
                            <Stack alignItems="center" direction="column" p={2} gap={0.5} key={element._id}>
                                <div
                                    style={{
                                        position: "relative",
                                        borderRadius: "1rem",
                                        padding: "1rem",
                                        border: isMatching ? "1px solid #A8ED1F" : undefined,
                                        backgroundColor: isMatching ? "#EFF2E9" : undefined,
                                        height:
                                            hasLinkedinOnDay1 &&
                                            (isRecruiterInMail || orgId === 1292) &&
                                            element?.eventName === "linkedin"
                                                ? "265px"
                                                : "135px",
                                    }}
                                >
                                    <OutReachElement {...element} nthFollowup={String(nthFollowup)} index={index} />
                                    {hasLinkedinOnDay1 &&
                                        !isSameDayInMailChecked &&
                                        (isRecruiterInMail || orgId === 1292) &&
                                        element?.eventName === "linkedin" && (
                                            <Typography className="text-center text-[10.5px] text-[#973643] mt-2">
                                                InMails triggered on first day of campaign will be queued to be sent on
                                                Day 2, to avoid automation detection.
                                            </Typography>
                                        )}

                                    {hasLinkedinOnDay1 &&
                                        isSameDayInMailChecked &&
                                        isRecruiterInMail &&
                                        element?.eventName === "linkedin" && (
                                            <Typography className="text-center text-[10.5px] text-[#973643] mt-2">
                                                The InMails are scheduled to be sent today. To ensure successful
                                                outreach, kindly refrain from using your LinkedIn recruiter account for
                                                approximately{""}{" "}
                                                <strong style={{ textDecoration: "underline" }}>
                                                    {apiTime ?? displayTime}
                                                </strong>
                                                .
                                            </Typography>
                                        )}
                                    {isRecruiterInMail && hasLinkedinOnDay1 && element?.eventName === "linkedin" && (
                                        <Tooltip
                                            className="max-w-[350px]"
                                            placement="bottom"
                                            title={
                                                <>
                                                    <p style={{ marginBottom: "4px" }}>
                                                        This feature sends InMails on the same day for LinkedIn
                                                        Recruiter accounts, even if it is triggered on Day 1. However,
                                                        you need to keep your LinkedIn Recruiter account inactive during
                                                        the specified time.
                                                    </p>
                                                    Note:
                                                    <ul>
                                                        <li style={{ marginBottom: "6px" }}>
                                                            a. Strictly close the active tabs for LinkedIn Recruiter for
                                                            specified time frame, after the scheduled time of sending
                                                            InMails.
                                                        </li>
                                                        <li style={{ marginBottom: "6px" }}>
                                                            b. If the daily limit of sending InMails (150/ day) is
                                                            exceeded, the remaining InMails will be scheduled for the
                                                            next day.
                                                        </li>
                                                    </ul>
                                                </>
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginLeft: "15px",
                                                    marginTop: "2px",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: "12px",
                                                        marginRight: "4px",
                                                        paddingTop: "10px",
                                                        color: "#757575",
                                                    }}
                                                >
                                                    Send InMail on day 1
                                                </p>
                                                <Checkbox
                                                    size="sm"
                                                    className="mt-2.5 ml-2 w-[17px] h-[17px]"
                                                    checked={isSameDayInMailChecked}
                                                    onChange={handleSameDayRecruiterInmails}
                                                />
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>

                                {!isLast && <div className="h-full min-h-[50px] w-px bg-gray-300"></div>}
                            </Stack>
                        );
                    })}
                </div>
            </Stack>
        </CustomStack>
    );
}

function TemplatesHeader({
    calendlyMenu,
    personalizationMenu,
}: {
    calendlyMenu?: React.ReactNode;
    personalizationMenu?: React.ReactNode;
}) {
    const dispatch = useDispatch();
    const isRecruiterInMail = useSelector(checkRecruiterInMail);
    const { currentOutreachElementId, isHyperPersonalizedView, projectId } = useOutreachParams();
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );
    const isMobileViewOn = useSelector((state: RootState) => state.outreach.isMobileViewOn);
    const templateType = outreachTooltipMapping[currentElementType as OutReachEvent];
    const text = isHyperPersonalizedView ? `${templateType} preview for` : `${templateType ?? ""} template`;

    const onClickToggle = () => {
        dispatch(toggleMobileView({ currentOutreachElementId }));
    };

    const padding = 0.9;

    const { data: writingStylesData = {} } = useFetchWritingStylesQuery({
        projectId,
    });

    return (
        <Stack
            style={{ backgroundColor: "#f5f5f5" }}
            px={1.5}
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="space-between"
        >
            <Stack direction="row" gap={0.5} alignItems="center" py={padding}>
                <Stack direction="row" alignItems="center" gap={1}>
                    {templateType && <CustomTitle>{text}</CustomTitle>}

                    {currentElementType === "linkedin" && (
                        <Tooltip
                            title={
                                isRecruiterInMail
                                    ? "LinkedIn allows you to send both connection requests and InMails to new people. With your LinkedIn Recruiter account, you can choose to send both or either one"
                                    : "LinkedIn allows you to send connection requests or send InMails to new people. InMails can only be sent if the other person has an 'Open to InMails' setting"
                            }
                        >
                            <InfoIcon sx={{ mr: 1, fontSize: "18px", color: "grey" }} />
                        </Tooltip>
                    )}
                </Stack>
                {isHyperPersonalizedView && templateType && <CandidatesMenu />}
                {isHyperPersonalizedView &&
                    (currentElementType === "email" ||
                        currentElementType === "follow-up" ||
                        currentElementType === "SMS") && (
                        <Stack
                            alignItems={"center"}
                            justifyContent={"center"}
                            className="flex flex-row ml-1 gap-6 hover:cursor-pointer"
                        >
                            {isMobileViewOn ? (
                                <Tooltip title="Desktop preview">
                                    <div onClick={onClickToggle}>
                                        <ComputerIcon sx={{ fontSize: "20px", color: "grey" }} />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Mobile view shows how your email will fit on a typical iPhone screen (iPhones are used by nearly 40% of the US population)">
                                    <div onClick={onClickToggle}>
                                        <PhoneAndroidIcon sx={{ fontSize: "20px", color: "grey" }} />
                                    </div>
                                </Tooltip>
                            )}
                            {isHyperPersonalizedView && writingStylesData?.applyStyle && (
                                <Tooltip title="Your writing style has been applied to this template">
                                    <Badge badgeContent={<DoneAllIcon sx={{ color: "green" }} fontSize="small" />}>
                                        <DesignServicesIcon
                                            sx={{ fontSize: "20px", color: "#0891B2" }}
                                            fontSize="small"
                                        />
                                    </Badge>
                                </Tooltip>
                            )}
                        </Stack>
                    )}
            </Stack>
            {!isHyperPersonalizedView && (
                <Stack direction="row" gap={1.5} alignItems="center">
                    <AttachmentList />
                    <Stack p={padding} direction="row" alignItems={"center"} gap={1.5}>
                        <MimicWritingStyles />
                        <FetchFromTemplate type={currentElementType} />
                        <AttachmentsModal />
                        <Stack id="calendly-personalization" direction="row" alignItems={"center"} gap={1.5}>
                            {calendlyMenu}
                            {personalizationMenu}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}

function TemplatePreview() {
    return (
        <TemplateEditorProvider>
            <CustomStack>
                <TemplatesHeader calendlyMenu={<CalendlyMenu />} personalizationMenu={<PersonalizedMenu />} />
                <hr className="border-t border-gray-300" />
                <div id="outreach-template" className="flex flex-grow overflow-scroll gap-2 bg-gray-50">
                    <div className="w-full">
                        <Outlet />
                    </div>
                </div>
                <TemplatesSubmission />
            </CustomStack>
        </TemplateEditorProvider>
    );
}

function TemplatesSubmission() {
    const { isUploadingImage } = useRichTextEditorContext();
    const [testEmailModal, setTestEmailModal] = useState(false);
    const dispatch = useAppDispatch();
    const {
        isHyperPersonalizedView,
        navigateToHyperPersonalizedTemplates,
        navigateToBaseTemplates,
        currentOutreachElementId,
    } = useOutreachParams();

    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );
    const isErrorInOutreachElement = useSelector((state: RootState) =>
        checkErrorInOutreachElement(state, currentOutreachElementId)
    );

    const isErrorsInCurrentOutreachTemplate = useSelector((state: RootState) =>
        checkErrorInOutreachElement(state, currentOutreachElementId)
    );
    const isErrorInOutreach = useSelector(checkErrorsInOutreach);
    const {
        saveAllTemplates,
        saveMutationState: { isLoading: isSavingTemplate },
    } = useSaveMultipleOutreachTemplates({
        successCallback: () => {
            dispatch(setSuccessNotification("Template saved successfully"));
        },
    });

    const handlePreviewClick = () => {
        navigateToHyperPersonalizedTemplates();
    };

    const disableSaveButton = isSavingTemplate || isErrorInOutreach || isUploadingImage;

    const disablePreviewButton = isErrorsInCurrentOutreachTemplate;

    const onCloseTestEmailModal = () => {
        setTestEmailModal(false);
    };

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                p={1.5}
                gap={1.5}
                pr={2}
                // sx={(theme) => ({
                //     borderTop: `1px solid lightgrey`,
                //     backgroundColor: theme.palette.grey[50],
                // })}
            >
                {isHyperPersonalizedView ? (
                    <Tooltip title="Edit template" placement="top">
                        <CustomButton
                            onClick={navigateToBaseTemplates}
                            className="text-sm border-0 shadow-none hover:opacity-[0.8]"
                        >
                            Edit
                        </CustomButton>
                    </Tooltip>
                ) : (
                    <>
                        {(currentElementType === "email" || currentElementType === "follow-up") && (
                            <Tooltip
                                placement="top"
                                title={
                                    isErrorsInCurrentOutreachTemplate ? (
                                        <>
                                            Please resolve template errors (
                                            <WarningRoundedIcon sx={{ fontSize: "11px" }} /> marked in red) to test your
                                            email content and formatting.
                                        </>
                                    ) : (
                                        "Test your email content and formatting"
                                    )
                                }
                            >
                                <CustomButton
                                    onClick={() => setTestEmailModal(true)}
                                    disabled={isErrorInOutreachElement}
                                    variant="outline"
                                    className="text-[13px] py-[0.4rem] px-[1rem] rounded-[0.3rem] bg-transparent shadow-none text-[#0891B2] hover:opacity-100 hover:bg-[#0891B2] hover:text-white self-end"
                                >
                                    Test email
                                </CustomButton>
                            </Tooltip>
                        )}
                        <Stack direction="row" alignItems="center" gap={1}>
                            {isSavingTemplate && (
                                <Stack direction="row" alignItems="center" gap={0.5}>
                                    <Typography variant="body2">Saving....</Typography>
                                </Stack>
                            )}
                            <Tooltip
                                title={
                                    isErrorInOutreach ? (
                                        <>
                                            Please resolve template errors (
                                            <WarningRoundedIcon sx={{ fontSize: "11px" }} /> marked in red) to save
                                            workflow
                                        </>
                                    ) : (
                                        "Save your entire workflow, including all your templates and time schedule"
                                    )
                                }
                                placement="top"
                            >
                                <CustomButton
                                    disabled={disableSaveButton}
                                    onClick={saveAllTemplates}
                                    className="text-[13px] py-[0.4rem] px-[1rem] rounded-[0.3rem] bg-[#0891B2] border-0 shadow-none hover:bg-[#0891B2]/70"
                                >
                                    Save workflow
                                </CustomButton>
                            </Tooltip>
                        </Stack>
                        <Tooltip
                            title={
                                isErrorsInCurrentOutreachTemplate ? (
                                    <>
                                        Please resolve template errors (<WarningRoundedIcon sx={{ fontSize: "11px" }} />{" "}
                                        marked in red) to preview template
                                    </>
                                ) : (
                                    "Preview template"
                                )
                            }
                            placement="top"
                        >
                            <CustomButton
                                onClick={handlePreviewClick}
                                disabled={disablePreviewButton}
                                // className="text-[13px] py-1.5 px-4 rounded-[0.3rem] bg-[#0891B2] border-0 shadow-none hover:bg-opacity-70"
                            >
                                Preview
                            </CustomButton>
                        </Tooltip>
                    </>
                )}
            </Stack>
            {testEmailModal && <TestEmailModal onClose={onCloseTestEmailModal} />}
        </>
    );
}
function TotalCandidatesCount() {
    const { t } = useTranslation();
    const { outreachId } = useOutreachParams();
    const { data = [], isLoading } = useFetchOutreachCandidatesQuery({
        outreachId,
    });

    const totalCandidates = data?.length;

    if (isLoading) {
        return <Skeleton className="w-[210px] h-[30px] bg-[#c1cad3]" />;
    }

    return (
        <Tooltip
            arrow
            title={`${t("common")} in workflow may be fewer than selected ${t("common")}. This is because any ${t("common")} marked excluded will not be part of this workflow.`}
            placement="right"
        >
            <Chip
                variant="secondary"
                label={
                    totalCandidates === 1
                        ? `${totalCandidates} ${t("commonSingular")} in workflow`
                        : `${totalCandidates} ${t("common")} in workflow`
                }
                style={{
                    borderRadius: "0.3rem",
                    fontWeight: "400",
                    padding: "7px",
                    fontSize: "12px",
                    backgroundColor: "#ebebeb",
                }}
            />
        </Tooltip>
    );
}

function OutReachHeader({ templateType }: { templateType: string }) {
    const projectName = useSelector(selectProjectName);
    const navigate = useNavigate();
    const location = useLocation();
    const isOutreachTemplatesReady = useSelector(checkIfOutreachReady);
    const outreach = useSelector(selectOutReachFlow);
    const user = useSelector(selectUser);
    const isLinkedInAuthorized = useSelector(checkIsLinkedinAuthorized);
    const isEmailAuthorized = user.emailAuthorized;

    const isEmailPresent = outreach.find((item) => item?.eventName === "email");
    const isLinkedinPresent = outreach.find((item) => item?.eventName === "linkedin");

    const { projectId } = useOutreachParams();

    const handleScheduleOutreach = () => {
        if (location.pathname[location.pathname.length - 1] === "/") {
            navigate(`${location.pathname}schedule`);
        } else {
            navigate(`${location.pathname}/schedule`);
        }
    };

    const handleBackClick = () => {
        navigate(`/projects/${projectId}/`);
    };

    const isDisabled =
        !isOutreachTemplatesReady ||
        !templateType ||
        (isEmailPresent && !isEmailAuthorized) ||
        (isLinkedinPresent && !isLinkedInAuthorized);

    const disableReason = !isDisabled ? (
        ""
    ) : !isOutreachTemplatesReady ? (
        <>
            Please <strong>Save Workflow</strong> before scheduling outreach
        </>
    ) : isEmailPresent && !isEmailAuthorized ? (
        "Email needs to be authorized first"
    ) : (
        "Linkedin needs to be authorized first"
    );

    return (
        <Stack
            p={0.5}
            py={0.7}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                minHeight: "50px",
                backgroundColor: "white",
            }}
        >
            <Stack direction="row" alignItems="center" gap={1}>
                <Tooltip title="Back">
                    <button onClick={handleBackClick}>
                        <ArrowBackRoundedIcon fontSize="small" />
                    </button>
                </Tooltip>
                <TotalCandidatesCount />
                {projectName && (
                    <Chip
                        label={projectName}
                        style={{
                            fontWeight: "500",
                            padding: "6px",
                            backgroundColor: "white",
                            color: "#0891B2",
                            border: "1px solid #0891B2",
                            borderRadius: "0.3rem",
                        }}
                    />
                )}
            </Stack>
            <Stack direction="row" gap={1.5}>
                <ExclusionList />
                <Tooltip title={isDisabled ? disableReason : ""}>
                    <div>
                        <CustomButton
                            id="schedule-outreach"
                            disabled={isDisabled}
                            onClick={handleScheduleOutreach}
                            className={`
                            text-sm 
                            px-3 py-1.5 
                            rounded-md 
                            mr-4 
                            h-9
                            bg-green-600 
                            border border-green-600 
                            shadow-none 
                            hover:bg-green-700/70 
                            disabled:bg-green-700/70
                        `}
                        >
                            Schedule Outreach
                        </CustomButton>
                    </div>
                </Tooltip>
            </Stack>
        </Stack>
    );
}

function ErrorPlaceholder({ handleRefetch }: { handleRefetch: () => void }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
            }}
        >
            <img src={errorImage} height={500} width={500} alt="error" style={{ objectFit: "cover" }} />
            <Typography variant="h6" className="text-light">
                Unexpected error occurred while fetching outreach details...
            </Typography>
            <CustomButton
                onClick={handleRefetch}
                style={{ marginTop: "1rem", boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)" }}
                startDecorator={<RestartAltIcon />}
            >
                Try again
            </CustomButton>
        </div>
    );
}

export function OutReachPage() {
    const { outreachId, projectId } = useOutreachParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProject({ projectId }));
    }, [projectId]);

    const [hideTourModal, setHideTourModal] = useLocalStorageState("outreach-tour-modal");
    const [, setShowTour] = useLocalStorageState("outreach-tour");

    const { isLoading, isError, refetch } = useFetchOutreachByIdQuery(outreachId);
    const { currentOutreachElementId } = useOutreachParams();
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );
    const templateType = outreachTooltipMapping[currentElementType as OutReachEvent];

    const handleRefetch = () => refetch();

    if (isError) {
        return <ErrorPlaceholder handleRefetch={handleRefetch} />;
    }

    if (isLoading) {
        return <MUILoader />;
    }

    return (
        <>
            <Stack
                style={{ height: hideTourModal === undefined || hideTourModal === false ? "85%" : "100%" }}
                className={style["zoomHandler"]}
            >
                <Navbar />
                {(hideTourModal === undefined || hideTourModal === false) && (
                    <Stack
                        sx={{
                            marginBottom: 1,
                            background: "#D1FFBD",
                            borderRadius: "0.5rem",
                        }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        p={1}
                        direction={"row"}
                    >
                        <Typography className="color-[black] text-center" variant="body2">
                            Reaching out is now easier and more intuitive. Take a 15-second tour here
                        </Typography>
                        <JoyProvider>
                            <Stack gap={1} alignItems={"center"} direction={"row"}>
                                <Button onClick={() => setShowTour(true)} size="sm">
                                    Start tour
                                </Button>
                                <Button
                                    variant="outline"
                                    onClick={() => {
                                        setShowTour(false);
                                        setHideTourModal(true);
                                    }}
                                >
                                    <CloseRoundedIcon />
                                </Button>
                            </Stack>
                        </JoyProvider>
                    </Stack>
                )}

                <Stack
                    style={{
                        border: "1px solid lightgrey",
                        height: "93%",
                        borderRadius: "7px",
                        padding: 1,
                    }}
                >
                    <OutReachHeader templateType={templateType} />
                    <hr className="border-t border-gray-300" />
                    <div
                        style={{
                            display: "grid",
                            height: "100%",
                            gridTemplateColumns: "250px auto 1fr",
                            maxHeight: "100%",
                            overflow: "hidden",
                        }}
                    >
                        <OutReachFlow />
                        <div className="h-full w-px bg-gray-300"></div>
                        {templateType && <TemplatePreview />}
                    </div>
                </Stack>
            </Stack>
            <WarningModal />
            <SaveModal />
        </>
    );
}
